<template>
  <div
    class="flex flex-col overflow-hidden text-gray-900 bg-white border border-gray-300 rounded-lg dark:bg-gray-600 dark:border-gray-500 dark:text-gray-200"
  >
    <div class="flex items-center justify-between flex-shrink-0 h-16 mx-4">
      <div class="flex flex-col">
        <h1 v-tooltip="title" class="text-sm font-bold truncate max-w-40">{{ title }}</h1>
        <h2 v-if="subtitle" class="text-xs">{{ subtitle }}</h2>
      </div>
      <div class="flex space-x-2 text-center">
        <svg-icon :name="icon === 'topics' ? 'postit' : 'user'" />
        <span class="text-xs text-gray-300 min-w-6">{{ count }}</span>
        <button v-if="collapsible" @click="toggleOpen">
          <svg-icon class="h-3" :name="arrowName" />
        </button>
      </div>
    </div>
    <div v-if="open || !collapsible" class="flex flex-col min-h-0">
      <div class="flex-1 min-h-0 overflow-y-auto">
        <slot />
      </div>
      <slot name="fixed-bottom" />
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue';

export default {
  name: 'SidebarDrawer',
  components: {
    SvgIcon
  },
  props: {
    collapsible: { type: Boolean, default: true },
    count: { type: [Number, String], required: true },
    icon: {
      type: String,
      required: true,
      validator: (value) => {
        return ['participants', 'topics'].includes(value);
      }
    },
    open: { type: Boolean, default: false },
    subtitle: { type: String, default: null },
    title: { type: String, required: true }
  },
  computed: {
    arrowName() {
      return this.open ? 'arrow-up' : 'arrow-down';
    }
  },
  methods: {
    toggleOpen() {
      this.$emit('toggle', this.open);
    }
  }
};
</script>
