<template>
  <img
    ref="image"
    :src="activity.image"
    :style="`background: ${selected ? activity.backgroundColor : 'transparent'}`"
  />
</template>

<script>
export default {
  name: 'ActivityImage',
  props: {
    activity: { type: Object, required: true },
    selected: { type: Boolean, default: false }
  }
};
</script>
